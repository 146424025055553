import React from "react"

export const boost = (amount) => {
  if (typeof amount === "number") {
    if (amount < 1) {
      return 0
    }
    return amount === 1
      ? 50
      : amount === 2
      ? 80
      : amount < 102
      ? (amount - 2) * 2 + 80
      : 280
  } else {
    return 0
  }
}

export const isSSR = typeof window === "undefined"
export const isError = (e) => e instanceof Error

export const truncate = (x, y = 8) => {
  return x ? `${x.substring(0, y)}...${x.slice(-y)}` : ""
}

export const format = (x, precision = 0) => {
  return precision
    ? parseInt(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (parseFloat(x) - parseInt(x))
          .toFixed(precision)
          .toString()
          .replace("0.", ".")
          .replace("1.", ".")
    : parseInt(x)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const formatValue = (value, postfix = "") => {
  return (
    <span>
      {value || "—"}
      {value && postfix ? postfix : ""}
    </span>
  )
}

export const randomHSL = () => {
  return `hsla(${~~(360 * Math.random())},70%,70%,1)`
}
