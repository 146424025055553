import axios from "axios"
import { notification } from "antd"

const client = axios.create({
  baseURL: "https://api-mainnet-graphql.raynet.work/",
})

client.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.data?.errors) {
      error?.response?.data?.errors.forEach((e) => {
        notification.warning({
          message: 'Graphql API :: Error',
          description: e.message
        })
      })
    }
    return error
  },
)
export const getNetworkInfo = async () => {
  return client.post("/", {
    query: `
      {
        cardano {
          tip {
            number
            slotNo
          }
          currentEpoch {
            number
            startedAt
            blocksCount
          }
        }
      }
    `,
  })
}
