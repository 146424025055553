import "antd/es/style/index.css"
import "antd/es/button/style/index.css"
import "antd/es/tooltip/style/index.css"
import "antd/es/input/style/index.css"
import "antd/es/notification/style/index.css"
import "antd/es/message/style/index.css"
// import "antd/es/popover/style/index.css"
// import "antd/es/alert/style/index.css"
// import "antd/es/drawer/style/index.css"
// import "antd/es/spin/style/index.css"
// import "antd/es/dropdown/style/index.css"
// import "antd/es/table/style/index.css"
// import "antd/es/upload/style/index.css"
// import "antd/es/checkbox/style/index.css"
// import "antd/es/radio/style/index.css"
// import "antd/es/form/style/index.css"
// import "antd/es/input-number/style/index.css"
// import "antd/es/modal/style/index.css"
// import "antd/es/badge/style/index.css"
// import "antd/es/result/style/index.css"
// import "antd/es/statistic/style/index.css"
// import "antd/es/pagination/style/index.css"
// import "antd/es/select/style/index.css"
// import "antd/es/collapse/style/index.css"

import "aos/dist/aos.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "@/styles/style.scss"

import AOS from "aos"
import store from "@/redux/provider"
// import { isSSR } from "@/utils"

AOS.init({ duration: 1000, once: true })
export const wrapRootElement = store

// // show update button on service worker update
// export const onServiceWorkerUpdateReady = () => {
//   if (!isSSR) {
//     document.getElementById('updater').classList.remove('d-none')
//   }
// }

// // prevent scroll to top on route change
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   // const { pathname } = location
//   // const scrollToTopRoutes = ['/', '/cardano/calculator/', '/cardano/faq/']
//   // if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//   //   window.scrollTo(0, 0)
//   // }

//   return false
// }