import { useDispatch } from "react-redux"
import { isSSR } from "@/utils"

const Runner = ({ children }) => {
  const dispatch = useDispatch()

  if (!isSSR) {
    setInterval(() => {
      dispatch({ type: "settings/FETCH_NETWORK_STATE" })
    }, 1 * 60 * 1000)
  }

  return children
}

export default Runner
